body {
  font-size: 0.875rem;
  background-color: #eff3f6;
}

.feather {
  width: 16px;
  height: 16px;
  vertical-align: text-bottom;
}
a {
  color: #d66f47;
  cursor: pointer;
}
/*
   * Sidebar
   */

.sidebar {
  position: fixed;
  top: 0;
  /* rtl:raw:
    right: 0;
    */
  bottom: 0;
  /* rtl:remove */
  left: 0;
  z-index: 100; /* Behind the navbar */
  padding: 65px 0 0; /* Height of navbar */
  box-shadow: inset -1px 0 0 rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
}

.sidebar-sticky {
  position: relative;
  top: 0;
  height: calc(100vh - 48px);
  padding-top: 0.5rem;
  overflow-x: hidden;
  overflow-y: auto; /* Scrollable contents if viewport is shorter than content. */
}

.sidebar .nav-link {
  font-weight: 500;
  color: #333!important;
}

.sidebar .nav-link .feather {
  margin-right: 4px;
  color: #727272;
}

.sidebar .nav-link.active {
  color: #d66f47!important;
}
.sidebar .nav-link:hover {
  color: #d66f47!important;
}
.sidebar .nav-link:hover .feather,
.sidebar .nav-link.active .feather {
  color: inherit;
}
.downloadRight{padding-right: 20px;}


.sidebar-heading {
  font-size: 0.75rem;
  text-transform: uppercase;
}

/*
   * Navbar
   */

.navbar-brand {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  font-size: 1rem;
  background-color: #292d30;
}

.navbar .navbar-toggler {
  top: 0.25rem;
  right: 1rem;
}

.navbar .form-control {
  padding: 0.75rem 1rem;
  border-width: 0;
  border-radius: 0;
}
.sidebar .nav-link {
  padding: 0.8rem 1rem;
}
.sidebar .nav-link i {
  margin-right: 9px;
}
.sidebar .nav-link i.fa-brands {
  font-size: 1.3em;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #d66f47;
}

.topIcons {
  margin-top: 9px;
  border-right: 1px solid #727272;
  padding-right: 3em;
  margin-right: 2em;
}

/*right part*/

.rightPart h3 {
  font-size: 1.3em;
}
.rightPart .card-title {
  font-size: 0.9rem;
  margin-bottom: 0;
 
}
.graphItem h5.card-title{
  color: #000!important;
}
.rightPart .card-img-overlay {
  top: inherit;
  padding: 0.7rem;
}
.rightPart .card {
  border: none;
}
.knowItems {
  padding: 16px 23px !important;
}
.rightPart .row > * {
  padding-right: calc(var(--bs-gutter-x) * 0.2);
  padding-left: calc(var(--bs-gutter-x) * 0.2);
  margin-top: var(--bs-gutter-y);
}
.rightPart .graphItem {
  padding: 3px 8px !important;
}
.fs-7 {
  font-size: 0.7rem;
}
.graphItem h5,
.graphItem h6 {
  margin-bottom: 0;
}
.graphItem .p-3 {
  padding: 0.5rem 1rem !important;
}
.graphItem table th {
  color: #505050;
}
.rightPart .nav-link {
  color: #666666!important;
}
.rightPart .nav-link {
  color: #666666;
}
.rightPart .nav-link:hover,
.rightPart .nav-link.active {
  color: #df8255!important;
}
/* .rightPart .nav-link:hover,
.rightPart .nav-link.active {
  color: #000;
} */

.btn-primary {
  color: #fff!important;
  background-color: #df8255 !important;
  border-color: #dc8355 !important;
  padding: 6px 21px;
}
.btn-theme {
  color: #fff;
  background-color: #df8255;
  border-color: #dc8355;
  border-radius: 20px;
  padding: 6px 21px;
}
.btn-black {
  color: #fff;
  background-color: #343434;
  border-color: #343434;
  border-radius: 20px;
  padding: 6px 21px;
}
.btn-theme:hover,
.btn-black:hover {
  background-color: #d66f47;
  border-color: #d66f47;
}
.filterTab .form-control,
.filterTab .btn.round {
  border-radius: 50px !important;
}
.filterTab .btn.round {
  background: #666666;
  color: #fff;
}

/*SignIn*/
.form-signin {
  width: 100%;
  max-width: 350px;
  padding: 15px;
  margin: auto;
  position: absolute;
  top: 50%;
  margin-top: -190px;
  left: 50%;
  margin-left: -175px;
  background: #fff;
  box-shadow: 0px 12px 16px 0px rgb(0 0 0 / 10%);
}

.form-signin .checkbox {
  font-weight: 400;
}

.form-signin .form-floating:focus-within {
  z-index: 2;
}

.form-signin input[type="email"] {
  margin-bottom: -1px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.form-signin input[type="password"] {
  margin-bottom: 10px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

/*Intranet*/
.xxsm-font {
  font-size: 9px;
}
.xsm-font {
  font-size: 11px;
}
.text-theme {
  color: #d66f47;
}
.latestImg {
  max-height: 210px;
  height: 100vh;
}
.graphItem .list-group-flush {
  height: 300px;
  overflow: auto;
}
.graphItem .list-group-flush li {
  padding: 0.3rem 1rem;
  border: none;
}
.graphItem .list-group-flush li i {
  display: inline-block;
  margin-left: -15px;
}
.graphItem .list-group-flush li a {
  color: inherit;
  text-decoration: none;
}
.graphItem .list-group-flush li a:hover {
  text-decoration: underline;
}
.conLead .rounded-circle {
  margin-top: -18px;
}
.conLead span.profileName {
  display: inline-block;
  margin-top: 9px;
}
.activityBy {
  border-radius: 5px;
  padding: 8px;
  margin-top: 10px;
  padding-top: 15px;
}
.PraisedCircle {
  border-radius: 50%;
  background-color: #d66f47;
  width: 20px;
  height: 20px;
}
.PraisedCircle i {
  margin-left: 0 !important;
  color: #fff;
}

.rightPart .uploadRound {
  border-radius: 20px !important;
}
.card-footer {
  background-color: #fff;
}
.card a h3,
.card a {
  color: #000;
  text-decoration: none;
}
.card a:hover h3,
.card a:hover {
  color: #d66f47;
}
.navbar-nav .dropdown-menu {
  position: absolute;
}
.btn-check:focus + .btn-secondary,
.btn-secondary:focus,
.btn-secondary {
  background: none;
  border: none;
}
h4 {
  font-size: 1.1rem;
}
h2 {
  font-size: 1.6rem;
}
a {
  color: #d66f47;
}
.figure a {
  text-decoration: none;
  color: inherit;
}
.card a {
  color: #fff;
  display: block;
  height: 180px;
}
.card a img {
  height: 100%;
  width: 100%;
}
.card a:hover {
  color: #000;
}
/* ///////////////////////// */
.show {
  display: block;
}
.hide {
  display: none;
}
#load {
  /* visibility: hidden; */
  width: 100%;
  text-align: center;
  left: 0;
  height: 100%;
  position: fixed;
  z-index: 9999;
  background: url("../../public/images/ajax-loader.gif") no-repeat center center
    rgba(255, 255, 255, 0.6);
}
.load {
  /* visibility: hidden; */
  width: 100%;
  text-align: center;
  left: 0;
  height: 100%;
  position: fixed;
  z-index: 9999;
}
.paginationlist li {
  list-style-type: none;
  float: left;
  padding-right: 2px;
}
.paginationlist li a {
  color: black;
  float: left;
  padding: 8px 16px;
  text-decoration: none;
  transition: background-color 0.3s;
  border: 1px solid #ddd;
}

.paginationlist li a.selected {
  background-color: #212529;
  color: white;
  border: 1px solid #212529;
}
.whiteSpace {
  white-space: pre-line;
}
.error {
  color: #f00;
  font-size: 18px;
}
.success {
  color: rgb(7, 126, 29);
  font-size: 18px;
}
.warraning {
  color: hsl(352, 78%, 60%);
  font-size: 18px;
}
.figure a {
  text-decoration: none;
  color: inherit;
}
.txtcenter {
  text-align: center!important;
}
.hideClass {
  display: none;
}
.sessiontxt {
  font-size: 20px !important;
}
._2R-kh {
  display: inline !important;
}
figure.img-fixed {
  height: 228px;
}
figure.img-fixed img {
  width: 100%;
  height: 100%;
}
.link-a {
  color: #d66f47;
}
.card a img {
  height: 100%;
  text-align: center;
  margin: auto;
  background-size: cover;
}

.card a {
  color: #fff;
  display: block;
  height: 180px;
  text-align: center;
}
.invoiceTemp {
  height: inherit !important;
}
.card-img-top {
  width: inherit!important;
}

@media (max-width: 767.98px) {
  .sidebar {
    top: 5rem;
  }
}
@media (min-width: 768px) {
  .px-md-4 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
}
@media (max-width: 991px) {
  .card a {
      height: 120px;
  }
}
.gmap{
  height: 100vh;
  position: sticky;
}

.form-forget{
  max-width: 500px;margin: auto
}
.form-forget label{
  left: 40px;
}
.form-forget .input-group-text{
  height: 58px;
  cursor: pointer;
}

/* Guest Book */
.text-blue{
  color: #0049a0
}
.hasDatepicker {
  border-right: 0;
}
.input-group-text {
  background-color: rgba(255, 255, 255, 0.9);
  border: 1px solid #ced4da;
  color: #afafaf;
}
.cardSection{
  max-width: 470px;
  margin:20px auto;
}
.btn-green {
  color: #fff;
  background-color: #34c759;
  border-color: #34c759;
  padding: 8px 5rem;
}
.btn-green:hover{
  background: #df8255;
  border-color: #df8255 ;
  color: #fff;
}


.page-link, .CreateTrip label{
  color: #df8255;
}
.CreateTrip .form-noradious{border-radius: 10px!important;}

.CreateTrip .input-group:not(.has-validation)>:not(:last-child):not(.dropdown-toggle):not(.dropdown-menu) {
  border-top-right-radius: 0!important;
  border-bottom-right-radius: 0!important;
}
.ui-datepicker{
  z-index: 9999!important;
}
.imageBg{background-color: #efefef;padding: 50px;text-align: center;}
.testTrip .card-body{
  background-color: #df8255;
}
.testTrip {max-width: 800px;margin: 50px auto;}
.testTrip  a{
  height: inherit;
}
.testTrip .text-muted, .card-title{color: #fff!important;
}
.ItenrTab .tab-pane{
  padding: 30px 10px;
}
.CreateTrip .nav-tabs {
  border: 1px solid #dee2e6;
  display: inline-flex;
  border-radius: 10px;
  padding: 3px;
}
.CreateTrip .nav-tabs .nav-item.show .nav-link, .CreateTrip .nav-tabs .nav-link.active {
  color: #fff!important;
  background-color: #e18156;
  border-color: #df8255 #dee2e6 #fff;
  border-radius: 10px;
}
.CreateTrip .nav-item{
  padding: 3px 5px;
}
.CreateTrip .nav-tabs .nav-link{
  border-color: #e18156 #e18156 #e18156;
  border-radius: 10px;
  
}
.nav-tabs .nav-link:focus, .nav-tabs .nav-link:hover {
  border-color: #e18156 #e18156 #e18156;
  isolation: isolate;
  border-radius: 10px;
}
.btn-outline {
  color: #000;
  background-color: none !important;
  border-color: #ccc !important;
  padding: 6px 21px;
}
.Calender .ui-datepicker{
 width: 100%;
}
 .Calender .ui-datepicker td a{
  height: 70px!important;
}
.contSummary{border: 1px solid #ccc;margin-bottom: 30px;}
.contSummary .viewdetail{display: block;border-top: 1px solid #ccc;}
.contSummary .circle{
  background: #e18156; width: 30px;height: 30px;border-radius: 50%;display: inline-block;text-align: center;color: #fff;line-break: 30px;padding-top: 3px;margin: 0 5px;
}
.dropstart .dropdown-menu{
  min-width: 14rem;padding: 5px 17px;
}
.dropstart .dropdown-menu a{
  padding: 3px;display: block;
  color: #212529;
  text-decoration: none;
}
.dropstart .dropdown-menu a:hover{
  color: #e18156;
}
.dropstart .drop{
  display: block;
  padding: 0 10px;float: right;
}
.knowItems .air-btn{margin-top: -9px;}
.text-black{
  color: #000!important;
}
.knowItems {
  padding: 16px 13px !important;
}
.knowItems .card-body{
 padding-top: 0
}
.NewsItem .img-fluid{
 margin-top: 5px;
}
.NewsItem p{
  margin-bottom: 0
}
.NewsItem a{
  color: #000;
  text-align: left
}
.airList .card-body{
  padding: 0
}
.airList .card-body a{text-decoration: none;color: #000;}
.NewsItem .card img{
  height: auto!important;
}
.NewsItem .card a{
  height: auto!important;
}
@media (min-width: 576px){
  #ImportTemp .modal-dialog {
      max-width: 700px;
  }
}
@media (max-width: 767.98px) {
  .sidebar {
    top: 5rem;
  }
}
@media (min-width: 768px) {
  .px-md-4 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .topSpace{
    margin-top: 27px;
  }
}
@media (max-width: 991px) {
  .card a {
      height: 120px;
  }
}

.dateField{
  border-top-right-radius: 0!important;
    border-bottom-right-radius: 0!important;
}
.react-datepicker-wrapper{width: 80%!important;}
.message-green{color:green;text-align:left;font-size:24px;}
.txt-green{color:green;}
.text-right{float: right;}
.rdt .form-control{border: none;-webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }
  .updateEvent-image{width: 200px;}
  .updateEvent-image-deatil{width: 259px;}
  .updateEvent-image-deatil-view{width: 243px;}
  .updateEvent-image-deatil-download{width: 157px;}
  .upload-image{position: relative;background-color: #efebeb;PADDING: 5px;height: 100%;}
  .upload-image-txt{position:absolute;top: 15px;right: 15px;}
  .document-event{
    padding: 6px 8px;
    background-color: #efebeb;
    display: block;
    margin: 0;
    height: 41px;
  }
  .document-event-link{
     float: right;
  }
  .document-event i{font-size:12px!important;
  }
  .CreateTrip .form-date {
    border-top-right-radius: 0!important;
    border-bottom-right-radius: 0!important;
    }
    .disabled-link {
      pointer-events: none;
    }

    /* Accommodations Hotel CSS Start */
    .rightPart .hotelList .row > * {
      padding-right: calc(var(--bs-gutter-x) * 0.5)!important;
      padding-left: calc(var(--bs-gutter-x) * 0.9)!important;
      margin-top: var(--bs-gutter-y);
    }
    .hotelList .card a{display: inline-block;height: inherit;} 
    .hotelList .cardbox{
      box-shadow: 0px 4px 8px rgb(0 0 0 / 10%);
      margin: 15px 0;
    }
    .icons{
      background: url("../../public/images/facility.png") no-repeat;
      width: 37px;
      height: 30px;
      display: inline-block;
      float: left;
      margin-right: 11px;
      margin-bottom: 10px;
    }
    .icons.food{background-position: -9px -11px;}
    .icons.ac{background-position: -60px -11px;}
    .icons.tv{background-position: -119px -12px;}
    .icons.swim{background-position: -176px -11px;}
    .icons.wifi{background-position: -7px -71px;}
    .icons.cctv{background-position: -59px -74px;}
    .icons.fire{background-position: -116px -68px;}
    .icons.taxi{background-position: -181px -69px;}
    .bg-grey{background-color: #f9f9f9;}
    .cursorpoint{cursor: pointer;}
     /* Accommodations Hotel CSS End */

     .cardbox img {
      height: 278px;
      object-fit: cover;
     
  }
  .slider-wrapper img{height: 600px!important;}
  .cmyNjn,.sc-eDvSVe{width: 350px;height: 30px!important;}
  .stretched-link::after{display: none;}

  .LinedSection{
    border-top: 3px solid #323232;
    box-shadow: 0px 2px 3px rgb(0 0 0 / 10%);
    padding: 20px;
    margin-bottom: 20px;
  }
  /* .padLeft .col-md-6{
    margin-left: -10px;
    margin-right: -10px;
  } */
  .rightPart .hotelList .row.padLeft > * {
    padding-right: calc(var(--bs-gutter-x) * 0.5)!important;
    padding-left: calc(var(--bs-gutter-x) * 0.5)!important;
    margin-top: var(--bs-gutter-y);
  }
  .LinedSection .col-md-6,.LinedSection .col-md-4{
    margin-bottom: 8px;
  }
  
  .ScrolHeight{
    max-height: 90vh;
    overflow: auto;
    margin-right: 5px;
    display: block;
    height: 100%;
  }

  .AdvisorForms{
    max-width: 1200px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    margin: 0px auto 20px;
    padding: 0 19px;
  }

 .AdvisorForms .react-datepicker-wrapper
 {
  width: 100%!important;
 }

 .traval-box{float: left; width: 90%;padding-bottom: 5px;}
 .traval-addRemove{float: left; width: 5%;padding-left: 5px;}
 .tdwidth25{width: 12.5%;}
 #ResourceTab .accordion-button{
  padding: 0.7rem 1.25rem;
}
#ResourceTab .accordion-button:not(.collapsed){
  color: #dc8355 ;
}
.accordianMinHeight{min-height: 255px;}
.unPaidBooking{border: 1px solid #9d0505!important;}
.unPaidBooking button {color:#9d0505!important}
.unPaidBooking:focus {
  border-color: #9d0505!important;
  box-shadow:none!important;
}
.unPaidBooking button:focus {
  box-shadow:none!important;
}
.unPaidBooking:not(.collapsed) {
  color: #9d0505!important;
  box-shadow:none!important;
}
.paidBooking{border: 1px solid #059505!important;}
.paidBooking button {color:#059505!important;}
.paidBooking:focus {
  border-color: #059505!important;
  box-shadow:none!important;
}
.paidBooking button:focus {
  box-shadow:none!important;
}
.paidBooking:not(.collapsed) {
  color: #059505!important;
  box-shadow:none!important;
}
.paidBooking-txt{color:#059505}
.unPaidBooking-txt{color:#9d0505}
.txtright{text-align: right;float: right;}
.accordion-button-print::after {
  background-image: none!important;
 
}
.imgWidth{width: 100px;}
.information-border{border: solid 1px #ccc;}
.pdf-form-border{border-top: solid 1px #ccc;border-right: solid 1px #ccc;border-bottom: solid 1px #ccc;}
.information-content{padding: 10px 15px;}
.upperheadting{text-transform: uppercase;}
.dicKeyValues{max-height: 580px; overflow-y: auto;}
.h2content{
  position: absolute;
    right: 61px;
    top: 13px;
    z-index: 9;
}
/* ***************css by sumit************* */
.mainContainer{
  background-color: #fff;
}

.table_box{
  padding: 40px 10px;
}
.client_profile{
  margin-left: 10px;
}
.client_profile .form-control{
  border-radius: 35px;
  font-size: 12px;
}
.button_client-profile .btn{
  font-size: 12px;
}
.report_box{
  box-shadow: 2px 2px 5px 8px rgba(206, 206, 206, 0.6);
  border-radius: 14px;
  padding:20px 0;
  margin-bottom: 40px;
}
.report_heading{
  padding-left: 20px;
  color: #df8255;
}
.report_table_box td{
  font-size: 12px;
  font-weight:500;

}
.report_table_box th{
  font-size: 12px;
}
.table_search_btn .btn{
  font-size: 12px;
  border: 1px solid rgb(184, 184, 184);
  padding: 5px 5px;
}
.table_search_btn .fa-solid{
  font-size: 10px;
  padding-left: 5px;
}
.form-control{
  font-size: 12px;
}
/* ********************invoice******************** */
.invoice_box{
  margin-left: 150px;
  margin-right: 50px;
}

.button_invoice .btn{
  font-size: 14px;
  padding: 5px 40px;
}
.invoice_text p{
  font-size: 14px;
  line-height: 0.3rem;
  font-weight: 500;
  color: #4d4d4d;
}
.invoice_text span{
  color: #0049a0;
}
@media (min-width: 576px){
  #exampleModal .modal-dialog {
  max-width: 1000px;
  margin: 1.75rem auto;
}}

/* Special offer css */
.specialoffer{
  padding: 40px 0;
  background-color: #eff3f6;
}
.special-offer-box{
  background-color: #fff;
 
}
.heding-offer h3{
 padding: 8px 40px;
  font-size: 28px;
  font-weight: 700;

}
.image-box{
  height: 299px;
    text-align: center;
    overflow: hidden;
    background: #fff;
    border: 1px solid #ccc;
}
.image-box img{
 width: 100%;
}
.special-image{
  display: flex;
  justify-content: space-between; 
  margin-top: 30px;
}
.special-image h3{
  font-size: 26px;
  color: #000;
  font-weight: 700;
  padding: 0 10px;
}
.special-image  a{
   color: #000;
   transition: all 0.3s;
   font-size: 15px;
}
.special-image h3 a:hover{
  color: #c76e4c;
}
.search-box{
  display: flex;
  justify-content: space-between;
  background-color: #eff3f6 ;
  border-radius: 40px;
  margin: 10px 20px;
  padding: 6px;
}
.input-box{
  width: 100%;
    padding-left: 16px;
}
.input-box input{
  background-color: transparent;
  border: none;
  font-size:20px;
  padding: 9px;
  font-weight: 500;
}
.input-box input::-webkit-input-placeholder{
  color: #000;
}
.input-box input:focus{
  box-shadow: none;
  background-color: transparent;
}
.button-search button{
  border: none;
    padding: 10px 20px;
    background-color: #df8255;
    border-radius: 30px;
    font-size: 21px;
    font-weight: 700;
    text-transform: uppercase;
    color: #fff;
}
.cyprus-cruise{
  box-shadow: 0px 6px 24px 0px rgba(0, 0, 0, 0.20);
  border-radius: 5px;
  margin: 0 15px;
}

.cyprus-cruise .text{
  background-color: #fff;
  border-radius: 5px;
  position: relative;
  overflow: hidden;
}

 .text h3{
  font-size: 22px;
  font-weight:  700;
  padding: 10px 15px;
}
.text{
  position: relative;
  overflow: hidden;
}
.offer{
  background-color: #df8255;
  border-bottom: 1px solid #c76e4c;
}
.offer h4{
  font-size: 16px;
  font-weight: 700;
  text-transform: uppercase;
  color: #fff;
  padding: 8px 15px;
}

.offer-icon{
  display: flex;
}
.offer-icon .date{
  width:50%;
  padding: 10px 15px;
}
.date-icon{
  display: flex;
  gap: 20px;
}
.date-icon span{
  font-size: 17px;
}
.date-icon span h5{
    font-size: 18px;
    line-height: 1rem;
}
.text-price{
  padding: 20px 25px;
}
.text-price h4{
  font-size: 17px;
  font-weight: bold;
}
.text-price p{
  font-size: 15px;
  font-weight:500;
}
.special-offer-details{
  background-color: #fff;
  
}

.special-offer-details h2{
  font-size: 30px;
  font-weight: 700;
  /* padding-left: 30px; */
}
.frequently_text .accordion-item {
  background-color: #fff;
  border: none;
}
.frequently_text .accordion-body{
  font-size: 15px;
  font-weight: 500;
}
.frequently_text .accordion-button {
  position: relative;
  /* display: flex; */
  align-items: center;
  width: 100%;
  padding: 1rem 1.25rem;
  font-size: 0.5rem;
  color: #212529;
  text-align: left;
  background-color: #fff;
  border: 0;
  border-radius: 0;
  overflow-anchor: none;
  font-size: 18px;

}
.frequently_text .accordion-button:not(.collapsed) {
  color: #000000;
  background-color: #ffffff;
  box-shadow: none;
  
}
.frequently_text .accordion-button:focus {
  z-index: 3;
  border-color: #ffffff;
  outline: 0;
  box-shadow:none;
}
.frequently_text .accordion-button:not(.collapsed)::after {
  background-image:url("../../public/images/minus-circle.png");
  transform: rotate(-180deg);
}
.frequently_text .accordion-button::after {
  flex-shrink: 0;
  width: 1.25rem;
  height: 1.25rem;
  margin-left: auto;
  content: "";
  background-image:url("../../public/images/plus-circle.png");
  background-repeat: no-repeat;
  background-size: 1.25rem;
  transition: transform 0.2s ease-in-out;
}

@media  (max-width:767px) {
  .date-icon span h5 {
    font-size: 16px;
    line-height:0.8rem;
}
.date-icon span {
  font-size: 18px;
}
.date-icon {
  display: flex;
  gap: 10px;
}
}
.textwidth150{
  width: 150px;
}
.textwidth120{
  width: 120px;
}
.textwidth100{
  width: 100px;
}
.textgreen{color: #059505;}
.txtred{color: #f00;}
.textwhite{color: #fff;}
.addGroupBox{
  box-shadow: 0px 1px 6px 3px rgba(0, 0, 0, 0.1);
  padding: 30px 4px 30px 30px;
  margin: 30px 8px;
  border-radius: 30px;
  overflow: auto;
}

/* Travel Rule (6 March 2024)*/
.scrollBox{
  overflow: auto;
  height: 200px;
}
#CreateMapping .modal-dialog,#UpdateMappingUser .modal-dialog{
  max-width: 908px;
}
